import moment from 'moment-timezone';
import { i18n } from '../../global/constants.js';
import { SelectOption } from '../../global/models';
import { ICON_BUILDER_MAP } from './builderIcons';
import { ButtonType } from './models';

export const EMPTY_BUTTON_ID = -1;

export const BEM_BLOCK_NAME = 'digital-program-builder';
export const BEM_BLOCK_NAME_SERVICES = 'digital-program-builder-services';
export const BEM_BLOCK_NAME_CUSTOM_OPTION = 'digital_program_builder_form';
export const BEM_BLOCK_NAME_BUTTON_CONTENT_CONTAINER = 'digital_program--button_content_container';
export const BEM_BLOCK_NAME_LEAD_TIME = 'digital-program-builder-lead-time';

export const BUTTON_TYPE_LABELS = {
    [ButtonType.Announcement]: 'Announcements',
    [ButtonType.ConnectionFormTemplate]: 'Connection Form',
    [ButtonType.Link]: 'External Link',
    [ButtonType.GivingDesignation]: 'Giving',
    [ButtonType.Journey]: 'Journey',
    [ButtonType.SermonOutline]: 'Notes',
};

export const ICON_BUILDER_OPTIONS = Object.entries(ICON_BUILDER_MAP).map(([key]) => ({
    label: key,
    value: key,
}));

export const COLOR_BUILDER_OPTIONS = [
    {
        label: 'Sky',
        value: '#6DCFF6',
    },
    {
        label: 'Sky - Dark',
        value: '#4095B6',
    },
    {
        label: 'Cyan',
        value: '#00AEEF',
    },
    {
        label: 'Cyan - Dark',
        value: '#0093C6',
    },
    {
        label: 'Blue',
        value: '#134174',
    },
    {
        label: 'Green',
        value: '#54CD86',
    },
    {
        label: 'Green - Dark',
        value: '#398F5D',
    },
    {
        label: 'Teal',
        value: '#56C4C4',
    },
    {
        label: 'Teal - Dark',
        value: '#329594',
    },
    {
        label: 'Purple',
        value: '#C68EF6',
    },
    {
        label: 'Purple - Dark',
        value: '#9958D2',
    },
    {
        label: 'Pink',
        value: '#EB4EA1',
    },
    {
        label: 'Pink - Dark',
        value: '#C23680',
    },
    {
        label: 'Red',
        value: '#EE4334',
    },
    {
        label: 'Red - Dark',
        value: '#C2241A',
    },
    {
        label: 'Red Orange',
        value: '#F47A46',
    },
    {
        label: 'Red Orange - Dark',
        value: '#D94710',
    },
    {
        label: 'Orange',
        value: '#F99E49',
    },
    {
        label: 'Orange - Dark',
        value: '#C96D20',
    },
    {
        label: 'Gray',
        value: '#DBE0E3',
    },
    {
        label: 'Gray - Dark',
        value: '#97A4AB',
    },
];

export const DEFAULT_FILTER_OPTIONS = {
    endDate: moment().endOf('day'),
    sort: 'date,asc',
    startDate: moment().startOf('day'),
};

export const SORT_OPTIONS: SelectOption<string, string>[] = [
    {
        label: i18n('Service Date (Asc)'),
        value: 'date,asc',
    }, {
        label: i18n('Service Date (Desc)'),
        value: 'date,desc',
    },
];

export const DEFAULT_LEAD_TIME_VALUE: number = 5;

export const DEFAULT_LEAD_TIME_HOURS =
    (service) => Math.floor((service?.leadTime ?? 0) / 60);

export const DEFAULT_LEAD_TIME_MINUTES =
    (service) => (service?.leadTime ?? DEFAULT_LEAD_TIME_VALUE) % 60;
