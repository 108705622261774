/* eslint-disable import/prefer-default-export */
/* eslint-disable quote-props */
import React from 'react';
import * as IconsV2 from 'hc-app-icons/dist/generated/v2/web';

export const ICON_BUILDER_MAP = {
    'Activate': (props) => <IconsV2.IconActivate {...props} />,
    'Add': (props) => <IconsV2.IconAddSolid {...props} />,
    'Announcements': (props) => <IconsV2.IconAnnouncementsSolid {...props} />,
    'Baptism': (props) => <IconsV2.IconBaptismSolid {...props} />,
    'Bitcoin': (props) => <IconsV2.IconBitcoinSolid {...props} />,
    'Book Open': (props) => <IconsV2.IconBookOpenSolid {...props} />,
    'Book': (props) => <IconsV2.IconBookSolid {...props} />,
    'Calendar': (props) => <IconsV2.IconCalendarSolid {...props} />,
    'Calendar Clock': (props) => <IconsV2.IconCalendarclockSolid {...props} />,
    'Check': (props) => <IconsV2.IconCheckSolid {...props} />,
    'Checklist': (props) => <IconsV2.IconChecklistSolid {...props} />,
    'Children': (props) => <IconsV2.IconChildrenSolid {...props} />,
    'Church': (props) => <IconsV2.IconChurchSolid {...props} />,
    'Circle Add': (props) => <IconsV2.IconCircleAddSolid {...props} />,
    'Circle Check': (props) => <IconsV2.IconCircleCheckSolid {...props} />,
    'Circle Close': (props) => <IconsV2.IconCircleCloseSolid {...props} />,
    'Circle Info': (props) => <IconsV2.IconCircleInfoSolid {...props} />,
    'Circle User': (props) => <IconsV2.IconCircleUserSolid {...props} />,
    'Clock': (props) => <IconsV2.IconClockSolid {...props} />,
    'Clockback': (props) => <IconsV2.IconClockbackSolid {...props} />,
    'Comments': (props) => <IconsV2.IconCommentsSolid {...props} />,
    'Community2': (props) => <IconsV2.IconCommunity2Solid {...props} />,
    'Community': (props) => <IconsV2.IconCommunitySolid {...props} />,
    'Compass': (props) => <IconsV2.IconCompassSolid {...props} />,
    'Contact': (props) => <IconsV2.IconContactSolid {...props} />,
    'Cross': (props) => <IconsV2.IconCrossSolid {...props} />,
    'DAF': (props) => <IconsV2.IconDafSolid {...props} />,
    'Devices': (props) => <IconsV2.IconDevicesSolid {...props} />,
    'Digitalprogram': (props) => <IconsV2.IconDigitalprogramSolid {...props} />,
    'Directions2': (props) => <IconsV2.IconDirections2Solid {...props} />,
    'Directions': (props) => <IconsV2.IconDirectionsSolid {...props} />,
    'Document': (props) => <IconsV2.IconDocumentSolid {...props} />,
    'Download': (props) => <IconsV2.IconDownloadSolid {...props} />,
    'Edit': (props) => <IconsV2.IconEditSolid {...props} />,
    'Edit Calendar': (props) => <IconsV2.IconEditcalendarSolid {...props} />,
    'Extensions': (props) => <IconsV2.IconExtensionsSolid {...props} />,
    'Facebook': (props) => <IconsV2.IconFacebook {...props} />,
    'Feedback': (props) => <IconsV2.IconFeedbackSolid {...props} />,
    'Gift': (props) => <IconsV2.IconGiftSolid {...props} />,
    'Globe': (props) => <IconsV2.IconGlobeSolid {...props} />,
    'Heart': (props) => <IconsV2.IconHeartSolid {...props} />,
    'Hospital': (props) => <IconsV2.IconHospitalSolid {...props} />,
    'House': (props) => <IconsV2.IconHouseSolid {...props} />,
    'Instagram': (props) => <IconsV2.IconInstagram {...props} />,
    'Interested': (props) => <IconsV2.IconInterestedSolid {...props} />,
    'Landline Phone': (props) => <IconsV2.IconLandlinephoneSolid {...props} />,
    'Leaf': (props) => <IconsV2.IconLeafSolid {...props} />,
    'List': (props) => <IconsV2.IconListSolid {...props} />,
    'Location': (props) => <IconsV2.IconLocationSolid {...props} />,
    'Location Searching': (props) => <IconsV2.IconLocationsearchingSolid {...props} />,
    'Mail': (props) => <IconsV2.IconMailSolid {...props} />,
    'Map': (props) => <IconsV2.IconMapSolid {...props} />,
    'Meal': (props) => <IconsV2.IconMealSolid {...props} />,
    'Medical': (props) => <IconsV2.IconMedicalSolid {...props} />,
    'Memorial': (props) => <IconsV2.IconMemorialSolid {...props} />,
    'Mobile Phone': (props) => <IconsV2.IconMobilephoneSolid {...props} />,
    'Money': (props) => <IconsV2.IconMoneySolid {...props} />,
    'Music': (props) => <IconsV2.IconMusicSolid {...props} />,
    'Newspaper': (props) => <IconsV2.IconNewspaperSolid {...props} />,
    'Nextstep': (props) => <IconsV2.IconNextstepSolid {...props} />,
    'Note': (props) => <IconsV2.IconNoteSolid {...props} />,
    'Notes': (props) => <IconsV2.IconNotesSolid {...props} />,
    'Office': (props) => <IconsV2.IconOfficeSolid {...props} />,
    'Office Phone': (props) => <IconsV2.IconOfficephoneSolid {...props} />,
    'Plan a Visit': (props) => <IconsV2.IconPlanavisitSolid {...props} />,
    'Play': (props) => <IconsV2.IconPlaySolid {...props} />,
    'Podcast': (props) => <IconsV2.IconPodcastSolid {...props} />,
    'Popout': (props) => <IconsV2.IconPopoutSolid {...props} />,
    'Prayer': (props) => <IconsV2.IconPrayerSolid {...props} />,
    'Privacy Policy': (props) => <IconsV2.IconPrivacypolicySolid {...props} />,
    'QR Code': (props) => <IconsV2.IconQrCodeSolid {...props} />,
    'Question': (props) => <IconsV2.IconQuestionSolid {...props} />,
    'Quick Scan': (props) => <IconsV2.IconQuickscanSolid {...props} />,
    'Quiet Time': (props) => <IconsV2.IconQuiettimeSolid {...props} />,
    'Relief': (props) => <IconsV2.IconReliefSolid {...props} />,
    'Repeat': (props) => <IconsV2.IconRepeatSolid {...props} />,
    'Right Arrow': (props) => <IconsV2.IconRightarrowSolid {...props} />,
    'Route': (props) => <IconsV2.IconRouteSolid {...props} />,
    'Saddleback': (props) => <IconsV2.IconSaddlebackSolid {...props} />,
    'Search': (props) => <IconsV2.IconSearchSolid {...props} />,
    'Serve': (props) => <IconsV2.IconServeSolid {...props} />,
    'Settings': (props) => <IconsV2.IconSettingsSolid {...props} />,
    'Share': (props) => <IconsV2.IconShareSolid {...props} />,
    'Sharestory': (props) => <IconsV2.IconSharestorySolid {...props} />,
    'Sharing': (props) => <IconsV2.IconSharingSolid {...props} />,
    'Spotify': (props) => <IconsV2.IconSpotify {...props} />,
    'Steps': (props) => <IconsV2.IconStepsSolid {...props} />,
    'Stocks': (props) => <IconsV2.IconStocksSolid {...props} />,
    'Subscribe': (props) => <IconsV2.IconSubscribeSolid {...props} />,
    'Terms of Service': (props) => <IconsV2.IconTermsofserviceSolid {...props} />,
    'Ticket': (props) => <IconsV2.IconTicketSolid {...props} />,
    'Timer': (props) => <IconsV2.IconTimerSolid {...props} />,
    'Tools': (props) => <IconsV2.IconToolsSolid {...props} />,
    'Twitter': (props) => <IconsV2.IconTwitter {...props} />,
    'User': (props) => <IconsV2.IconUserSolid {...props} />,
    'View': (props) => <IconsV2.IconViewSolid {...props} />,
    'View Campus': (props) => <IconsV2.IconViewcampusSolid {...props} />,
    'Visit': (props) => <IconsV2.IconVisitSolid {...props} />,
    'Voice': (props) => <IconsV2.IconVoiceSolid {...props} />,
    'Volume': (props) => <IconsV2.IconVolumeSolid {...props} />,
    'Volume Off': (props) => <IconsV2.IconVolumeoffSolid {...props} />,
    'Volunteer': (props) => <IconsV2.IconVolunteerSolid {...props} />,
    'Worship': (props) => <IconsV2.IconWorshipSolid {...props} />,
    'Youtube': (props) => <IconsV2.IconYoutube {...props} />,
};
